import moment from 'moment';

export const expenseTypeConfig = {
  expenseTypeID: '',
  name: '',
  enable: true,
  tags: [],
};

export const expenseTagConfig = {
  name: '',
  projectCode: '',
  expenseCode: '',
  note: '',
  enable: true,
};

export const expenseParamsConfig = {
  basicPay: 0,
  requestPaymentDays: 0,
  stopRequestPaymentDays: 0,
  premiumRate: 0,
  localTaxRate: 0,
  foreignerTaxRate: 0,
  residentTaxRate: 0,
  workTaxRate: 0,
  under183TaxMultiple: 0,
  underMultipleTaxRate: 0,
  overMultipleTaxRate: 0,
  taxableAmount: 0,
  agentDifference: 0,
  agentDifferenceRate: 0,
  specialFeatureTaxableAmount: 0,
  shareInvoices: [],
};

export const workingHoursParams = {
  todoDays: 0,
  enableFreeTime: false,
  enableExtraPay: false,
  overTimeRemind: 0,
  overTimeLimit: 0,
  freeTimeLimit: 0,
  extraPayLimit: 0,
  normalDayExtraRate: {
    firstTwoHour: 0,
    overTwoHour: 0,
  },
  restDayExtraRate: {
    firstTwoHour: 0,
    overTwoHour: 0,
    overEightHour: 0,
  },
  holidayExtraRate: {
    underEightHour: 0,
    overEightHour: 0,
    overTenHour: 0,
    makeItADay: false,
  },
  nationalHolidayExtraRate: {
    underEightHour: 0,
    overEightHour: 0,
    overTenHour: 0,
    makeItADay: false,
  },
  overTimeTotalLimit: {
    month: 0,
    hours: 0,
  },
};

export const vendorTypeConfig = {
  vendorTypeID: '',
  name: '',
  englishName: '',
  enable: true,
  tags: [],
};

export const vendorTagConfig = {
  name: '',
  englishName: '',
  enable: true,
  personalService: false,
};

export const passwordParamsConfig = {
  validDays: 180,
  minLength: 8,
  maxLength: 30,
  errorLimit: 5,
};

export const workingHoursTypeConfig = {
  workingHoursTypeID: '',
  name: '',
  note: '',
  enable: true,
  tags: [],
  projectType: [],
};

export const workingHoursTagConfig = {
  name: '',
  note: '',
  enable: true,
};

export const todoTypeConfig = {
  todoTypeID: '',
  note: '',
  name: '',
  englishName: '',
  enable: true,
  tags: [],
  projectType: [],
};

export const todoTagConfig = {
  name: '',
  note: '',
  displayName: '',
  engDisplayName: '',
  serviceName: '',
  engServiceName: '',
  enable: true,
};

export const activityIdeaTypeConfig = {
  id: '',
  name: '',
  enable: true,
  tags: [],
};

export const activityIdeaTagConfig = {
  name: '',
  enable: true,
};

export const holidayParamsConfig = {
  year: null,
  holidays: [],
  restdays: [],
  workdays: [],
  nationalHolidays: [],
};

export const laborHealthInsuranceParamsConfig = {
  isEmployer: false,
  applyDate: moment().startOf('month').format('YYYY-MM-DD'),
  insuranceGrade: 0,
  laborEmployerPay: 0,
  laborEmployeePay: 0,
  healthEmployerPay: 0,
  healthEmployeePay: 0,
};

export const leaveParamsConfig = {
  name: "",
  description: "",
  departmentId: "",
  applicableFor: "all",
  minLeaveMinutes: 0,
  isPaid: true,
  salaryMultiplier: 0.0,
  paidLeaveHours: 0,
  areHolidaysCounted: false,
  expirationDays: 0,
  postExpiryAction: "gone",
  giveDurationType : "",
  hasMultipleApplications: false,
};

export const leaveGiveHourParamsConfig = {
  "onBoardFrom": 0,
  "onBoardTo": 0,
  "leaveHours": "",
};

export const leaveApprovalParamsConfig = {
  "approvalSequence": "",
};

export const extraPaymentsParamsConfig = {
  type: "add",
  name: "",
  startMonth: moment().startOf('month').format('YYYY-MM-DD'),
  recurringMonth: 1,
  amountForEachMonth: 0,
  forRecordOnly: false,
  recipientType: "list",
  recipients: [],
};

export const healthDependentParamsConfig = {
  name: "",
  relationship: "",
  healthInsurance: {
    "isMinor": false,
    "amount": 0,
  },
}

export const paycheckStructureParamsConfig = {
  userId: "",
  adjustDate: "",
  applyDate: null,
  isSenior: false,
  basicSalary: "",
  insuranceGrade: "",
  selfPayPensionPercentage: 0,
  healthPay: 0,
  dependents: [],
};

export const workdayHoursParams = {
  workdayStart: "",
  workdayEnd: "",
  lunchBreakStart: "",
  lunchBreakEnd: "",
}