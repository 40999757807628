import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { putNewVendor, postToGetVendors } from '@/api/vendorService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_SPECIAL_FEATURES,
  FETCH_VENDOR_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelVendorDetail from '@/components/Panel/PanelVendorDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { vendorConfig } from '@/constant/dataConfig/informationModule';
import { integerConverter } from '@/utils/textUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const VendorCreation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const vendorTypeOptions = useSelector(state => state.srpOptions.vendorTypeOptions);
  const vendorTagDictionary = useSelector(state => state.srpOptions.vendorTagDictionary);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const specialFeatures = useSelector(state => state.srpOptions.specialFeatures);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  const [vendor, setVendor] = useState({
    ...vendorConfig,
    tags: [],
    paymentMethods: [],
    transferInfo: { ...vendorConfig.transferInfo },
    contactAddress: { ...vendorConfig.contactAddress },
    invoiceAddress: { ...vendorConfig.invoiceAddress },
    specialProperties: {
      ...vendorConfig.specialProperties,
      personalServiceDetail: {
        ...vendorConfig.specialProperties.personalServiceDetail,
      },
    },
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const updateVendorHandler = (key, value) => {
    const updateObj = { ...vendor };
    updateObj[key] = value;
    setVendor({ ...updateObj });
  };

  const paymentChangedHandler = value => {
    const updateObject = { ...vendor };
    updateObject.paymentMethods = [ ...value ];

    if (value.indexOf('credit') === -1) {
      updateObject.credictCardOwner = '';
    }

    if (value.indexOf('transfer') === -1) {
      updateObject.transferInfo = {
        ...vendorConfig.transferInfo
      };
    }

    setVendor(updateObject);
  };

  const clearData = () => {
    setVendor({
      ...vendorConfig,
      tags: [],
      paymentMethods: [],
      transferInfo: { ...vendorConfig.transferInfo },
      contactAddress: { ...vendorConfig.contactAddress },
      invoiceAddress: { ...vendorConfig.invoiceAddress },
      specialProperties: {
        ...vendorConfig.specialProperties,
        personalServiceDetail: {
          ...vendorConfig.specialProperties.personalServiceDetail,
        },
      },
    });
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const saveNewVendorHandler = async () => {
    console.log("vendorCreation.js saveNewVendorHandler vendor:" + JSON.stringify(vendor));
    if (vendor.taxId !== "") {
      const { data } = await postToGetVendors(
        (
          { 
          taxId: vendor.taxId,
          page: 1,
          amount: 10,
          }
        )
      );
      console.log("vendorCreation.js saveNewVendorHandler data.totalCount:" + data.totalCount);
      if (data.totalCount >= 1) {
        console.log("vendorCreation.js saveNewVendorHandler data.totalCount:" + data.totalCount);
        return openAlertPopup('此統一編號已存在');
      }
    }
    console.log("vendorCreation.js saveNewVendorHandler");
    setShowLoadingPopup(true);

    try {
      await putNewVendor({
        ...vendor,
        transferInfo: {
          ...vendor.transferInfo,
          handlingFee: integerConverter(vendor.transferInfo.handlingFee),
        },
        specialProperties: {
          ...vendor.specialProperties,
          personalServiceDetail : {
            ...vendor.specialProperties.personalServiceDetail,
            wageB: integerConverter(vendor.specialProperties.personalServiceDetail.wageB),
            wageE: integerConverter(vendor.specialProperties.personalServiceDetail.wageE),
            specialFeature: vendor.specialProperties.personalServiceDetail.specialFeature || null,
          }
        },
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const closeSuccessPopupHandler = () => {
    clearData();
    setShowSaveSuccessPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_SPECIAL_FEATURES({ enable: true }));
    dispatch(FETCH_VENDOR_TYPE_OPTIONS({
      enable: true,
      enableTags: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  console.log(specialFeatures);

  return (
    <MainContent>
      <PanelVendorDetail
        title = "新增廠商資料"
        vendor = { vendor }
        memberOptions = { memberOptions }
        specialFeatures = { specialFeatures }
        vendorTypeOptions = { vendorTypeOptions }
        vendorTagDictionary = { vendorTagDictionary }
        updateVendorHandler = { updateVendorHandler }
        paymentChangedHandler = { paymentChangedHandler }
      >
      {
        <DivFlexRow>
          <DivButtonsWrapper>
            <div>
              <ButtonSave
                onClick = { saveNewVendorHandler }
              >儲存</ButtonSave>
              <ButtonClear
                onClick = { clearData }
              >清除欄位</ButtonClear>
            </div>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      }
      </PanelVendorDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "新增失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </MainContent>
  )
};

export default VendorCreation;
