import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useLocation } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";


import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupEditLeaveApplication from '@/components/UI/Popup/PopupEditLeaveApplication';

import moment from 'moment';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PanelLeaveSearch from '@/components/Panel/PanelLeaveSearch';
import TableLeaveList from '@/components/Table/Attendance/TableLeaveList';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportOvertimesFile } from '@/utils/xlsx';

import {
  getOptionDesc,
  getOvertimeApprovementStatus,
} from '@/utils/textUtils';

import {
  diffTime,
  formatTime,
} from '@/utils/timeUtils';

import {
  // getLeaveSetting,
  // postCreateLeaveApplication,
  getLeave,
  getLeaveApplications,
  postUpdateLeaveApplicationStatus,
  getLeaveApplicationById,
} from '@/api/financeService';

import { postToGetUsers } from '@/api/authService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

// import { leaveApplicationConfig } from '@/constant/dataConfig/financeModule';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  startDate: null,
  endDate: null,
  status: '',
  userIDs: [],
  projectID: '',
  typeID: '',
  tag: '',
  isOverTime: true,
  overtimeType: '',
  approvementStatus: '',
  crossTeam: false,
  amount: 10,
  leaveFrom: null,
  leaveTo: null,
  userFilter: 'self',
};

const userSearchConfig = {
  // isOverTime: true,
  code: '',
  name: '',
  hasCreditCard: '',
  department: '',
  director: '',
  enable: '',
  systemRole: [],
  projectRole: [],
};

const LeaveList = () => {
  const history = useHistory()
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchedWorkingStartDate = params.get('searchedWorkingStartDate');
  const searchedWorkingEndDate = params.get('searchedWorkingEndDate');
  // console.log("leaveList.js searchedWorkingStartDate:" + searchedWorkingStartDate);
  // console.log("overtimesList.js searchedWorkingEndDate:" + searchedWorkingEndDate);
  const projectRole = useSelector(state => state.login.projectRole);

  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  // console.log(`leaveList.js JSON.stringify(memberOptions)=${JSON.stringify(memberOptions)}`);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);

  // const [overtimeRecords, setOvertimeRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [userFilter, setUserFilter] = useState('self');
  const [userFilterOptions, setUserFilterOptions] = useState([]);
  const [searchUserOptions, setSearchUserOptions] = useState([]);
  const [userConfig, setUserConfig] = useState({ ...userSearchConfig });
  // const [config, setConfig] = useState({
  //   ...searchConfig,
  //   userIDs: [userID],
  // });
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const [leaveApplicationParam, setLeaveApplicationParam] = useState({ ...searchConfig });

  const [leaveApplicationRecords, setLeaveApplicationRecords] = useState([]);
  const [leaveSettings, setLeaveSettings] = useState([]);
  const [leaveSettinsOptions, setLeaveSettinsOptions] = useState([]);

  const [viewMode, setViewMode] = useState('list');
  const [leaveEventsList, setLeaveEventsList] = useState([]);
  const [editingLeaveApplication, setEditingLeaveApplication] = useState();
  const [showPopupEditLeaveApplication, setShowPopupEditLeaveApplication] = useState(false);

  const localizer = momentLocalizer(moment);
  // const allViews = Object.keys(Calendar.Views).map(k => Calendar.Views[k]);
  //const leaveEventsList = [
    // {
    //   id: 1,
    //   title: 'Ian Event',
    //   start: new Date(2024, 2, 7, 9, 0, 0),
    //   end: new Date(2024, 2, 10, 18, 0, 0),
    // },
    // {
    //   id: 2,
    //   title: 'Tina Event',
    //   start: new Date(2024, 2, 17, 9, 0, 0),
    //   end: new Date(2024, 2, 18, 18, 0, 0),
    // },
  // ];

  const updateConfigHandler = (key, value) => {
    console.log(`leaveList.js updateConfigHandler key=${key}`);
    const updateObj = { ...leaveApplicationParam };
    updateObj[key] = value;
    setLeaveApplicationParam({...updateObj});
    // if (key === "leaveTo") {
    //   fetchLeaveApplication();
    // }
    // const updateObj = { ...config };
    // updateObj[key] = value;
    // setConfig({...updateObj});
  };

  const updateViewModeHandler = (value) => {
    // console.log(`leaveList.js updateViewModeHandler value=${value}`);
    setViewMode(value);
  };

  // const typeChangeHandler = value => {
  //   setConfig(prevState => ({
  //     ...prevState,
  //     typeID: value,
  //     tag: '',
  //   }));
  // };

  // const userFilterChangeHandler = value => {
  //   setUserFilter(value);
  //   setConfig(prevState => ({
  //     ...prevState,
  //     userIDs: value === 'self' ? [userID] : [],
  //   }));
  // };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    setCurrentPageHandler(1);
  };

  const clearHandler = () => {
    setUserFilter('self');
    setLeaveApplicationParam({
      ...searchConfig,
      userIDs: [userID],
    });
  };

  const searchHandler = () => {
    //set searched config
    // const params = new URLSearchParams()
    // if (leaveApplicationParam.leaveFrom !== null && leaveApplicationParam.leaveTo !== null) {
    //   // console.log("overtimesList.js workingDateChangeHanlder date:" + value);
    //   params.append("searchedWorkingStartDate", formatTime(leaveApplicationParam.leaveFrom, 'YYYY-MM-DD'))
    //   params.append("searchedWorkingEndDate", formatTime(leaveApplicationParam.leaveTo, 'YYYY-MM-DD'))
    //   history.push({search: params.toString()})
    // }
    //set searched config
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchLeaveApplication(page);
    fetchLeaveApplicationOfCalendar();
    // fetchWorkingHours(page);
  };

  // const getApprovedMinutes = (overtimeRecord) => {
  //   if (overtimeRecord.approvementStatus !== 'approved') return 0;

  //   return overtimeRecord.overtimeType === 'freeTime'
  //     ? overtimeRecord.freeTimeMins
  //     : overtimeRecord.extraPayMins;
  // };

  // const exportHandler = async () => {
  //   setShowLoadingPopup(true);
  //   const overtimesData = [];

  //   try {
  //     const { data } = await postToGetWorkingHours(
  //       searchConfigGenerator({
  //         ...config,
  //         amount: totalCount || 1,
  //         page: 1,
  //       })
  //     );

  //     const { workingHours } = data;

  //     for (let i = 0; i < workingHours.length; i += 1 ) {
  //       const {
  //         projectID, userID, date, startTime, overtimeType,
  //         endTime, typeID, tag, approvementStatus,
  //       } = workingHours[i];
  //       const projectInfo = await postToGetProjectDetail(projectID);
  //       const userInfo = await postToGetUserInfo(userID);
  //       const { projectCode, projectName } = projectInfo.data;
  //       const { name } = userInfo.data;
  //       overtimesData.push([
  //         name,
  //         date,
  //         projectCode,
  //         projectName,
  //         getOptionDesc(workingHoursTypeOptions, typeID),
  //         tag,
  //         overtimeType === 'extraPay' ? '加班費' : '補休',
  //         `${formatTime(startTime, 'MM/DD HH:mm')} ~ ${formatTime(endTime, 'MM/DD HH:mm')}`,
  //         `${diffTime(startTime, endTime, 'minutes')}`,
  //         getOvertimeApprovementStatus(approvementStatus),
  //         getApprovedMinutes(workingHours[i]),
  //       ])
  //     }

  //   } catch (err) { console.log(err) }

  //   setShowLoadingPopup(false);
  //   exportOvertimesFile(overtimesData);
  // };

  const reloadLeaveApplicationByIdHandler = async (leaveApplicationId) => {
    try {
      console.log(`leaveList.js reloadLeaveApplicationById() leaveApplicationId=${leaveApplicationId}`);

      const { data } = await getLeaveApplicationById(leaveApplicationId);
      console.log(`leaveList.js reloadLeaveApplicationByIdHandler() JSON.stringify(data)=${JSON.stringify(data)}`);
      setEditingLeaveApplication(data);
      fetchLeaveApplicationOfCalendar();
    } catch (err) {
    }
  };

  const cancelLeaveApplicationHandler = async (leaveApplicationId) => {
    setShowLoadingPopup(true);
    // const { workingHoursID, freeTimeMins, extraPayMins } = leaveRecords[index];

    try {
      console.log(`leaveList.js cancelLeaveApplicationHandler() leaveApplicationId=${leaveApplicationId}`);

      await postUpdateLeaveApplicationStatus(leaveApplicationId, "cancelled");
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
      fetchLeaveApplication(currentPage);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const fetchLeaveSetting = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getLeave();
      console.log(`leaveList.js data=${JSON.stringify(data)}`);
      setLeaveSettings(data.leaves);
      const sorted = data.leaves.map(element => {
        return {value: element.id, desc: element.description };
      });
      console.log(`leaveList.js sorted=${JSON.stringify(sorted)}`);
      setLeaveSettinsOptions(sorted);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };
  
  const fetchLeaveApplication = async (date = searchConfig.leaveFrom, page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      console.log(`leaveList.js fetchLeaveApplication() userID=${userID}`);
      var searchParameter = {
        userId: userID,
        leaveFrom: moment(leaveApplicationParam.leaveFrom).format('YYYY-MM-DD'), 
        leaveTo: moment(leaveApplicationParam.leaveTo).format('YYYY-MM-DD'),
        status: leaveApplicationParam.status,
      };
      console.log(`leaveList.js fetchLeaveApplication() JSON.stringify(leaveApplicationParam)=${JSON.stringify(leaveApplicationParam)}`);
      if (leaveApplicationParam.userFilter === 'all') {
        delete searchParameter.userId;
      }
      const { data } = await getLeaveApplications(searchParameter);
      console.log(`leaveList.js fetchLeaveApplication() JSON.stringify(data)=${JSON.stringify(data)}`);
      const { applications } = data;
      const tempApplicationRecords = [];

      for (let i = 0; i < applications.length; i += 1 ) {
        tempApplicationRecords.push({
          ...applications[i],
        });
      }

      setLeaveApplicationRecords([ ...tempApplicationRecords ]);
      // setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const handleOnSelectEvent = useCallback(
    (event) => {
      console.log(`leaveList.js fetchLeaveApplicationOfCalendar() JSON.stringify(event)=${JSON.stringify(event)}`);
      // editingLeaveApplication
      if (projectRole.includes('hr')) {
        const tempLeaveApplication = leaveApplicationRecords.find(function(elem, i, array) {
          return elem.id === event.id;
        });
        console.log(`leaveList.js fetchLeaveApplicationOfCalendar() JSON.stringify(tempLeaveApplication)=${JSON.stringify(tempLeaveApplication)}`);
        setEditingLeaveApplication(tempLeaveApplication);
        setShowPopupEditLeaveApplication(true);
      }
    },[leaveApplicationRecords, projectRole]
  );

  const handleOnClosePopupEditLeaveApplication = useCallback(
    () => {
      setShowPopupEditLeaveApplication(false);
    },[]
  );

  const fetchLeaveApplicationOfCalendar = async (date = searchConfig.leaveFrom) => {
    // setShowLoadingPopup(true);

    try {
      console.log(`leaveList.js fetchLeaveApplicationOfCalendar() userID=${userID}`);
      var searchParameter = {
        userId: userID,
        leaveFrom: moment(leaveApplicationParam.leaveFrom).startOf("month").format('YYYY-MM-DD'), 
        leaveTo: moment(leaveApplicationParam.leaveTo).endOf("month").format('YYYY-MM-DD'),
        status: leaveApplicationParam.status,
      };
      console.log(`leaveList.js fetchLeaveApplicationOfCalendar() JSON.stringify(leaveApplicationParam)=${JSON.stringify(leaveApplicationParam)}`);
      if (leaveApplicationParam.userFilter === 'all') {
        delete searchParameter.userId;
      }
      const { data } = await getLeaveApplications(searchParameter);
      console.log(`leaveList.js fetchLeaveApplicationOfCalendar() JSON.stringify(data)=${JSON.stringify(data)}`);
      const temp = data.applications.map(elem => (
        {
          id: elem.id,
          title: elem.username + ' ' + elem.leaveName,
          start: new Date(
                      moment(elem.leaveFrom).format('YYYY'),
                      moment(elem.leaveFrom).format("M") - 1,
                      parseInt(moment(elem.leaveFrom).format("DD")),
                      // moment(elem.leaveFrom).format("HH"),
                      // moment(elem.leaveFrom).format("mm"),
                      // moment(elem.leaveFrom).format("ss"),
                  ),
          end: new Date(
                      moment(elem.leaveTo).format('YYYY'),
                      moment(elem.leaveTo).format("M") - 1,
                      parseInt(moment(elem.leaveTo).format("DD")),
                      0,
                      0,
                      1,
                      // moment(elem.leaveTo).format("HH"),
                      // moment(elem.leaveTo).format("mm"),
                      // moment(elem.leaveTo).format("ss"),
                  ),
          status: elem.status,
        } 
      ));
      setLeaveEventsList(temp);
      // const { applications } = data;
      // const tempApplicationRecords = [];

      // for (let i = 0; i < applications.length; i += 1 ) {
      //   tempApplicationRecords.push({
      //     ...applications[i],
      //   });
      // }

      // setLeaveApplicationRecords([ ...tempApplicationRecords ]);
      // setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  // const fetchWorkingHours = async (page = currentPage) => {
  //   setShowLoadingPopup(true);
  //   console.log("LeaveList.js config:" + JSON.stringify(config));
  //   // console.log("OvertimesList.js userFilter:" + userFilter);
  //   try {
  //     const tempConfig = searchConfigGenerator({ ...config, page });
  //     // console.log("tempConfig:" + JSON.stringify(tempConfig));
  //     if (userFilter === "self") {
  //       delete tempConfig.userIDs;
  //       tempConfig.userIDs = [userID];
  //     }
  //     // console.log("tempConfig:" + JSON.stringify(tempConfig));
  //     const { data } = await postToGetWorkingHours(
  //       tempConfig
  //     );

  //     const { workingHours, totalCount } = data;
  //     const workingRecords = [];

  //     for (let i = 0; i < workingHours.length; i += 1 ) {
  //       const { projectID, userID } = workingHours[i];
  //       const projectInfo = await postToGetProjectDetail(projectID);
  //       const userInfo = await postToGetUserInfo(userID);
  //       const { projectCode, projectName } = projectInfo.data;
  //       const { name } = userInfo.data;
  //       workingRecords.push({
  //         ...workingHours[i],
  //         username: name,
  //         projectCode,
  //         projectName,
  //       })
  //     }

  //     setOvertimeRecords([ ...workingRecords ]);
  //     setTotalCount(totalCount || 0);
  //   } catch (err) { console.log(err) }

  //   setShowLoadingPopup(false);
  // };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  const initLeaveSetting = useCallback(
    fetchLeaveSetting, []);

  useEffect(() => {
    initOptions();
    initLeaveSetting();
  }, [initOptions, initLeaveSetting]);


  const fetchUserFilterOptions = () => {
    if (projectRole.includes('hr')) {
      setUserFilterOptions([
        { value: 'self', desc: '自己' },
        { value: 'all', desc: '全部' },
      ]);
    } else if (projectRole.includes('director')) {
      setUserFilterOptions([
        { value: 'self', desc: '自己' },
        { value: 'all', desc: '全部' },
      ]);
    } else if (projectRole.includes('projectManager')) {
      setUserFilterOptions([
        { value: 'self', desc: '自己' },
        { value: 'all', desc: '全部' },
      ]);
    } else {
      setUserFilterOptions([
        { value: 'self', desc: '自己' },
      ]);
    }
  };

  // const fetchDepartmentMemberOptions = async () => {
  //   console.log(`leaveList.js fetchDepartmentMemberOptions() userID=${userID}`);
  //   if (projectRole.includes('director')) {
  //     const { data } = await postToGetUsers(
  //       searchConfigGenerator({
  //         ...userConfig, page: 1, amount: 1000,
  //       })
  //     )
  //     console.log(`leaveList.js fetchDepartmentMemberOptions() JSON.stringify(data)=${JSON.stringify(data)}`);
  //     const myData = data.users.filter((elem, i) => {
  //       return elem.userID === userID;
  //     });
  //     console.log(`leaveList.js fetchDepartmentMemberOptions() JSON.stringify(myData)=${JSON.stringify(myData)}`);
  //     const deaprtmentMembers = data.users.filter((elem) => elem.department === myData.department && elem.userID !== myData.userID);


  //     console.log(`leaveList.js fetchDepartmentMemberOptions() JSON.stringify(deaprtmentMembers)=${JSON.stringify(deaprtmentMembers)}`);
  //   }
  // };

  const initUserFilterOptions = useCallback(
    fetchUserFilterOptions  
    , []);

  // const initDepartmentMemberOptions = useCallback(
  //   fetchDepartmentMemberOptions
  //   , []);

  useEffect(() => {
    initUserFilterOptions();
    // initDepartmentMemberOptions();
  }, [initUserFilterOptions]);

  // const handlePreviousSearched = useCallback(() => {
  //   if (searchedWorkingStartDate !== undefined && searchedWorkingStartDate !== "" && searchedWorkingEndDate !== undefined && searchedWorkingEndDate !== "") {
  //     setConfig({
  //       ...searchConfig,
  //       startDate: searchedWorkingStartDate,
  //       endDate: searchedWorkingEndDate,
  //     });
  //   }
  // }, [searchedWorkingStartDate, searchedWorkingEndDate]);

  useEffect(() => {
    if (searchedWorkingStartDate !== undefined && searchedWorkingStartDate !== "" && searchedWorkingEndDate !== undefined && searchedWorkingEndDate !== "") {
      setLeaveApplicationParam({
        ...searchConfig,
        startDate: searchedWorkingStartDate,
        endDate: searchedWorkingEndDate,
      });
    }
  }, [searchedWorkingStartDate, searchedWorkingEndDate]);

  // useEffect(() => {
  //   async function fetchWorkingHoursByIan() {
  //     setShowLoadingPopup(true);
  //     console.log("leaveList.js fetchWorkingHoursByIan() config:" + JSON.stringify(config));
  //     const tempConfig = searchConfigGenerator({
  //       ...config, 
  //       startDate: formatTime(searchedWorkingStartDate, 'YYYY-MM-DD'),
  //       endDate: formatTime(searchedWorkingEndDate, 'YYYY-MM-DD'),
  //       amount: 10,
  //       page: 1 
  //     });
  //     // console.log("tempConfig:" + JSON.stringify(tempConfig));
  //     if (userFilter === "self") {
  //       delete tempConfig.userIDs;
  //       tempConfig.userIDs = [userID];
  //     }
  //     try {
  //       const { data } = await postToGetWorkingHours(tempConfig);

  //       const { workingHours, totalCount } = data;
  //       const workingRecords = [];

  //       for (let i = 0; i < workingHours.length; i += 1 ) {
  //         const { projectID, userID } = workingHours[i];
  //         const projectInfo = await postToGetProjectDetail(projectID);
  //         const userInfo = await postToGetUserInfo(userID);
  //         const { projectCode, projectName } = projectInfo.data;
  //         const { name } = userInfo.data;
  //         workingRecords.push({
  //           ...workingHours[i],
  //           username: name,
  //           projectCode,
  //           projectName,
  //         })
  //       }

  //       setOvertimeRecords([ ...workingRecords ]);
  //       setTotalCount(totalCount || 0);
  //     } catch (err) { console.log(err) }

  //     setShowLoadingPopup(false);
  //   }
  //   if (searchedWorkingStartDate !== null && searchedWorkingEndDate  != null) {
  //     console.log("overtimesList.js useEffect searchedWorkingStartDate" + searchedWorkingStartDate);
  //     fetchWorkingHoursByIan();
  //   }
  // }, [searchedWorkingStartDate, searchedWorkingEndDate]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelLeaveSearch
          config = { leaveApplicationParam }
          userFilter = { userFilter }
          memberOptions = { memberOptions }
          projectIDOptions = { projectIDOptions }
          workingHoursTypeOptions = { workingHoursTypeOptions }
          workingHoursTagDictionary = { workingHoursTagDictionary }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
          // exportHandler = { exportHandler }
          updateConfigHandler = { updateConfigHandler }
          viewMode = { viewMode }
          userFilterOptions = { userFilterOptions }
          updateViewModeHandler = { updateViewModeHandler }
          // typeChangeHandler = { typeChangeHandler }
          // userFilterChangeHandler = { userFilterChangeHandler }
        />
      </DivPanelContainer>
      { viewMode === "list" && 
        <TableLeaveList
          leaveApplicationRecords = { leaveApplicationRecords }
          leaveSettinsOptions = { leaveSettinsOptions }
          totalCount = { totalCount }
          currentPage = { currentPage }
          pageChangedHandler = { value => {
            setCurrentPageHandler(value);
          }}
          cancelLeaveApplicationHandler = { leaveApplicationId => {
            // console.log(`leaveList.js cancelLeaveApplicationHandler leaveApplicationId=${leaveApplicationId}`);
            cancelLeaveApplicationHandler(leaveApplicationId);
          }}
        />
      }
      { viewMode === "calendar" && 
        <div style={{ height: 800 }}>
          <Calendar
            date={ 
              leaveApplicationParam.leaveFrom === null ? 
                    new Date(
                        moment().format('YYYY'), 
                        moment().format('M') - 1, 
                        1)
              :
                    new Date(
                          parseInt(moment(leaveApplicationParam.leaveFrom).format('YYYY')), 
                          parseInt(moment(leaveApplicationParam.leaveFrom).format('M')) - 1, 
                          1)
              // new Date(
              //           parseInt(moment(leaveApplicationParam.leaveFrom).format('YYYY')), 
              //           parseInt(moment(leaveApplicationParam.leaveFrom).format('M')) - 1, 
              //           1) 
                  }
            // date={ new Date(2024, 0, 1) }
            localizer={localizer}
            events={ leaveEventsList }
            // startAccessor="start"
            // endAccessor="end"
            step={60}
            onSelectEvent = { handleOnSelectEvent }
            toolbar = { false }
            eventPropGetter={
              (event, start, end, isSelected) => {
                let newStyle = {
                  // background: "#3383FFFF",
                  // background: "repeating-linear-gradient(45deg, blue, red 33.3%)",
                  background: "repeating-linear-gradient(45deg, #3383FF, #3383FF 10px, #3396FF 10px, #3396FF 20px)",
                  color: 'black',
                  borderRadius: "10px",
                  border: "none"
                };
                console.log(`leaveList.js eventPropGetter {JSON.stringify(event)=${JSON.stringify(event)}`);
                if (event.status === "pending") {
                  newStyle.background = "#3383FF6F";
                }
          
                return {
                  className: "",
                  style: newStyle
                };
              }
            }
            // views={allViews}
            // style={{ height: 500 }}
          />
        </div>
      }
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "審核成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      {
        showPopupEditLeaveApplication &&
          <PopupEditLeaveApplication
            editingLeaveApplication = { editingLeaveApplication }
            cancelLeaveApplicationHandler = { cancelLeaveApplicationHandler }
            reloadLeaveApplicationByIdHandler = { reloadLeaveApplicationByIdHandler }
            handleOnClosePopupEditLeaveApplication = { handleOnClosePopupEditLeaveApplication }
          />
      }
    </MainContent>
  );
};

export default LeaveList;
