import React from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';
import styled from 'styled-components';
import DateSelector from '@/components/UI/DateSelector';

import { projectRolesOptions, onboardStateOptions, educationLevelOptions } from '@/constant/options';
import Checkbox from '@/components/UI/Checkbox';
import { formatTime } from '@/utils/timeUtils';

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const THead = styled(TableHead)`
  min-width: 1560px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const H3Title = styled.h3`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`; 

const PanelUserDetail = props => {
  const { user, userHistory } = props;
  
  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>帳號<span>*</span></PSubtitle>
          <InputBasic
            disabled = { props.disabled || props.disableAccount }
            value = { user.account }
            onChange = { ({ target }) => {
              props.updateUserHandler('account', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>密碼<span>*</span></PSubtitle>
          <InputBasic
            type = "password"
            disabled = { props.disabled || props.disablePassword }
            value = { user.password }
            onChange = { ({ target }) => {
              props.updateUserHandler('password', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>同仁編號</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { user.code }
            onChange = { ({ target }) => {
              props.updateUserHandler('code', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>同仁名稱<span>*</span></PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { user.name }
            onChange = { ({ target }) => {
              props.updateUserHandler('name', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>信用卡持卡人</PSubtitle>
          <SelectNormal
            disabled = { props.disableHRFields || props.disabled }
            value = { user.hasCreditCard }
            options = {[
              { value: true, desc: '是' },
              { value: false, desc: '否' },
            ]}
            changeHandler = { value => {
              props.updateUserHandler('hasCreditCard', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>顯示公司專案</PSubtitle>
          <SelectNormal
            disabled = { props.disableHRFields || props.disabled }
            value = { user.showProject }
            options = {[
              { value: true, desc: '是' },
              { value: false, desc: '否' },
            ]}
            changeHandler = { value => {
              props.updateUserHandler('showProject', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>部門</PSubtitle>
          <SelectNormal
            disabled = { props.disableHRFields || props.disabled }
            value = { user.department }
            options = {[
              { value: '', desc: '請選擇部門' },
              ...props.departmentOptions,
            ]}
            changeHandler = { value => {
              props.updateUserHandler('department', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>所屬總監</PSubtitle>
          <SelectNormal
            disabled = { props.disableHRFields || props.disabled }
            value = { user.director }
            options = {[
              { value: '', desc: '請選擇總監' },
              ...props.directorOptions,
            ]}
            changeHandler = { value => {
              props.updateUserHandler('director', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>E-Mail<span>*</span></PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { user.email }
            onChange = { ({ target }) => {
              props.updateUserHandler('email', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>E-Mail (私)<span>*</span></PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { user.emailPrivate }
            onChange = { ({ target }) => {
              props.updateUserHandler('emailPrivate', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>行動電話</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { user.phone }
            onChange = { ({ target }) => {
              props.updateUserHandler('phone', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            disabled = { props.disableHRFields || props.disabled }
            value = { user.companyType }
            options = {[
              { value: '', desc: '請選擇公司' },
              ...props.companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateUserHandler('companyType', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>狀態</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { user.enable }
            options = {[
              { value: true, desc: '啟用' },
              { value: false, desc: '停用' },
            ]}
            changeHandler = { value => {
              props.updateUserHandler('enable', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>起薪</PSubtitle>
          <InputBasic
            type = "number"
            disabled = { props.disableHRFields || props.disabled }
            value = { user.basicSalary }
            onChange = { ({ target }) => {
              props.updateUserHandler('basicSalary', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>性別</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { user.gender }
            options = {[
              { value: 0, desc: '女性' },
              { value: 1, desc: '男性' },
            ]}
            changeHandler = { value => {
              props.updateUserHandler('gender', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>學歷</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { user.educationLevel }
            options = { educationLevelOptions }
            changeHandler = { value => {
              props.updateUserHandler('educationLevel', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>通訊地址</PSubtitle>
          <InputBasic
            type = "text"
            disabled = { props.disabled }
            value = { user.addressForMail }
            onChange = { ({ target }) => {
              props.updateUserHandler('addressForMail', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>戶籍地址</PSubtitle>
          <InputBasic
            type = "text"
            disabled = { props.disabled }
            value = { user.addressOnId }
            onChange = { ({ target }) => {
              props.updateUserHandler('addressOnId', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>生日</PSubtitle>
          <DateSelector
              disabled = { props.disabled }
              value = { user.dob === undefined ? null : user.dob }
              changeHandler = { value => {
                props.updateUserHandler(
                  'dob',
                  value === null ? null : formatTime(value, 'YYYY-MM-DD'),
                );
              }}
            />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>身分證字號</PSubtitle>
          <InputBasic
            type = "text"
            disabled = { props.disabled }
            value = { user.idNo }
            onChange = { ({ target }) => {
              props.updateUserHandler('idNo', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>薪資轉帳帳號</PSubtitle>
          <InputBasic
            type = "text"
            disabled = { props.disabled }
            value = { user.bankAccount }
            onChange = { ({ target }) => {
              props.updateUserHandler('bankAccount', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>緊急聯絡人</PSubtitle>
          <InputBasic
            type = "text"
            disabled = { props.disabled }
            value = { user.emergencyContactName }
            onChange = { ({ target }) => {
              props.updateUserHandler('emergencyContactName', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>緊急聯絡人電話</PSubtitle>
          <InputBasic
            type = "text"
            disabled = { props.disabled }
            value = { user.emergencyContactPhone }
            onChange = { ({ target }) => {
              props.updateUserHandler('emergencyContactPhone', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>是否為雇主</PSubtitle>
            <SelectNormal
              disabled = { props.disableHRFields || props.disabled }
              value = { user.isEmployer }
              options = {[
                { value: true, desc: '是' },
                { value: false, desc: '否' },
              ]}
              changeHandler = { value => {
                props.updateUserHandler('isEmployer', value);
              }}
            />
          </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>專案角色</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              disabled = { props.disableHRFields || props.disabled }
              value = { user.projectRole }
              options = { projectRolesOptions }
              changeHandler = { value => {
                props.updateUserHandler('projectRole', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>權限設定</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              disabled = { props.disableHRFields || props.disabled }
              value = { user.systemRole }
              options = { props.systemRoleOptions }
              changeHandler = { value => {
                props.updateUserHandler('systemRole', value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>在職狀態</PSubtitle>
          <SelectNormal
            disabled = { props.disableHRFields || props.disabled }
            value = { user.state }
            options = { onboardStateOptions }
            changeHandler = { value => {
              props.updateUserHandler('state', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      {/* <DivFlexRow>
        <PSubtitle>在職紀錄</PSubtitle>
        <ButtonAdd
          onClick = { () => {  } }
        >新增在職紀錄
        </ButtonAdd>
      </DivFlexRow> */}
        {
          userHistory !== undefined && userHistory.map((history, index) => 
            <div>
              <DivFlexRow>
                <DivItemWrapper>
                </DivItemWrapper>
                <DivItemWrapper>
                  <Checkbox
                    title = "全職"
                    disabled = { props.disableHRFields || (props.disabled && history.isFullTime !== undefined) }
                    checked = { history.isFullTime }
                    changeHandler = { value => {
                      if (value) {
                        props.updateUserHistoryHandler('isFullTime', 1);
                      } else {
                        props.updateUserHistoryHandler('isFullTime', 0);
                      }
                    }}
                  />
                </DivItemWrapper>
              </DivFlexRow>
              <DivFlexRow>
                <DivItemWrapper>
                </DivItemWrapper>
                <DivItemWrapper>
                  <PSubtitle>到職日</PSubtitle>
                  <DateSelector
                    disabled = { props.disableHRFields || (props.disabled && history.isFullTime !== undefined) }
                    value = { history.onboardDate }
                    changeHandler = { value => {
                      props.updateUserHistoryHandler('onboardDate', formatTime(value, 'YYYY-MM-DD'));
                    }}
                  />
                </DivItemWrapper>
                <DivItemWrapper>
                  <PSubtitle>離職日</PSubtitle>
                  <DateSelector
                    disabled = { props.disableHRFields || 
                      (props.disabled && history.isFullTime !== undefined) || 
                      (user.state === "onboard") || 
                      index < userHistory.length - 1
                    }
                    value = { history.offboardDate === undefined ? null : history.offboardDate }
                    changeHandler = { value => {
                      props.updateUserHistoryHandler('offboardDate', formatTime(value, 'YYYY-MM-DD'));
                    }}
                  />
                </DivItemWrapper>
              </DivFlexRow>
            </div>
          )
        }
      { props.children }
    </TableWrapper>
  );
};

export default PanelUserDetail;
