import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useLocation } from "react-router-dom"

import { postToGetUserInfo } from '@/api/authService';
import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetWorkingHours } from '@/api/attendanceService';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PanelOvertimesSearch from '@/components/Panel/PanelOvertimesSearch';
import TableOvertimeList from '@/components/Table/Attendance/TableOvertimeList';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { exportOvertimesFile } from '@/utils/xlsx';

import {
  getOptionDesc,
  getOvertimeApprovementStatus,
} from '@/utils/textUtils';

import {
  diffTime,
  formatTime,
} from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const searchConfig = {
  startDate: null,
  endDate: null,
  userIDs: [],
  projectID: '',
  typeID: '',
  tag: '',
  isOverTime: true,
  overtimeType: '',
  approvementStatus: '',
  crossTeam: false,
  amount: 10,
};

const OvertimesList = () => {
  const history = useHistory()
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchedWorkingStartDate = params.get('searchedWorkingStartDate');
  const searchedWorkingEndDate = params.get('searchedWorkingEndDate');
  // console.log("overtimesList.js searchedWorkingStartDate:" + searchedWorkingStartDate);
  // console.log("overtimesList.js searchedWorkingEndDate:" + searchedWorkingEndDate);

  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);

  const [overtimeRecords, setOvertimeRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [userFilter, setUserFilter] = useState('self');
  const [config, setConfig] = useState({
    ...searchConfig,
    userIDs: [userID],
  });

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  const updateConfigHandler = (key, value) => {
    const updateObj = { ...config };
    updateObj[key] = value;
    setConfig({...updateObj});
  };

  const typeChangeHandler = value => {
    setConfig(prevState => ({
      ...prevState,
      typeID: value,
      tag: '',
    }));
  };

  const userFilterChangeHandler = value => {
    setUserFilter(value);
    setConfig(prevState => ({
      ...prevState,
      userIDs: value === 'self' ? [userID] : [],
    }));
  };

  const clearHandler = () => {
    setUserFilter('self');
    setConfig({
      ...searchConfig,
      userIDs: [userID],
    });
  };

  const searchHandler = () => {
    //set searched config
    const params = new URLSearchParams()
    if (config.startDate !== null && config.endDate != null) {
      // console.log("overtimesList.js workingDateChangeHanlder date:" + value);
      params.append("searchedWorkingStartDate", formatTime(config.startDate, 'YYYY-MM-DD'))
      params.append("searchedWorkingEndDate", formatTime(config.endDate, 'YYYY-MM-DD'))
      history.push({search: params.toString()})
    }
    //set searched config
    setCurrentPageHandler(1);
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchWorkingHours(page);
  };

  const getApprovedMinutes = (overtimeRecord) => {
    if (overtimeRecord.approvementStatus !== 'approved') return 0;

    return overtimeRecord.overtimeType === 'freeTime'
      ? overtimeRecord.freeTimeMins
      : overtimeRecord.extraPayMins;
  };

  const exportHandler = async () => {
    setShowLoadingPopup(true);
    const overtimesData = [];

    try {
      const { data } = await postToGetWorkingHours(
        searchConfigGenerator({
          ...config,
          amount: totalCount || 1,
          page: 1,
        })
      );

      const { workingHours } = data;

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const {
          projectID, userID, date, startTime, overtimeType,
          endTime, typeID, tag, approvementStatus,
        } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const userInfo = await postToGetUserInfo(userID);
        const { projectCode, projectName } = projectInfo.data;
        const { name } = userInfo.data;
        overtimesData.push([
          name,
          date,
          projectCode,
          projectName,
          getOptionDesc(workingHoursTypeOptions, typeID),
          tag,
          overtimeType === 'extraPay' ? '加班費' : '補休',
          `${formatTime(startTime, 'MM/DD HH:mm')} ~ ${formatTime(endTime, 'MM/DD HH:mm')}`,
          `${diffTime(startTime, endTime, 'minutes')}`,
          getOvertimeApprovementStatus(approvementStatus),
          getApprovedMinutes(workingHours[i]),
        ])
      }

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
    exportOvertimesFile(overtimesData);
  };

  const fetchWorkingHours = async (page = currentPage) => {
    setShowLoadingPopup(true);
    // console.log("OvertimesList.js config:" + JSON.stringify(config));
    // console.log("OvertimesList.js userFilter:" + userFilter);
    try {
      const tempConfig = searchConfigGenerator({ ...config, page });
      // console.log("tempConfig:" + JSON.stringify(tempConfig));
      if (userFilter === "self") {
        delete tempConfig.userIDs;
        tempConfig.userIDs = [userID];
      }
      // console.log("tempConfig:" + JSON.stringify(tempConfig));
      const { data } = await postToGetWorkingHours(
        tempConfig
      );

      const { workingHours, totalCount } = data;
      const workingRecords = [];

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const { projectID, userID } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const userInfo = await postToGetUserInfo(userID);
        const { projectCode, projectName } = projectInfo.data;
        const { name } = userInfo.data;
        workingRecords.push({
          ...workingHours[i],
          username: name,
          projectCode,
          projectName,
        })
      }

      setOvertimeRecords([ ...workingRecords ]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  // const handlePreviousSearched = useCallback(() => {
  //   if (searchedWorkingStartDate !== undefined && searchedWorkingStartDate !== "" && searchedWorkingEndDate !== undefined && searchedWorkingEndDate !== "") {
  //     setConfig({
  //       ...searchConfig,
  //       startDate: searchedWorkingStartDate,
  //       endDate: searchedWorkingEndDate,
  //     });
  //   }
  // }, [searchedWorkingStartDate, searchedWorkingEndDate]);

  useEffect(() => {
    if (searchedWorkingStartDate !== undefined && searchedWorkingStartDate !== "" && searchedWorkingEndDate !== undefined && searchedWorkingEndDate !== "") {
      setConfig({
        ...searchConfig,
        startDate: searchedWorkingStartDate,
        endDate: searchedWorkingEndDate,
      });
    }
  }, [searchedWorkingStartDate, searchedWorkingEndDate]);

  useEffect(() => {
    async function fetchWorkingHoursByIan() {
      setShowLoadingPopup(true);
      // console.log("overtimesList.js config:" + JSON.stringify(config));
      const tempConfig = searchConfigGenerator({
        ...config, 
        startDate: formatTime(searchedWorkingStartDate, 'YYYY-MM-DD'),
        endDate: formatTime(searchedWorkingEndDate, 'YYYY-MM-DD'),
        amount: 10,
        page: 1 
      });
      // console.log("tempConfig:" + JSON.stringify(tempConfig));
      if (userFilter === "self") {
        delete tempConfig.userIDs;
        tempConfig.userIDs = [userID];
      }
      try {
        const { data } = await postToGetWorkingHours(tempConfig);

        const { workingHours, totalCount } = data;
        const workingRecords = [];

        for (let i = 0; i < workingHours.length; i += 1 ) {
          const { projectID, userID } = workingHours[i];
          const projectInfo = await postToGetProjectDetail(projectID);
          const userInfo = await postToGetUserInfo(userID);
          const { projectCode, projectName } = projectInfo.data;
          const { name } = userInfo.data;
          workingRecords.push({
            ...workingHours[i],
            username: name,
            projectCode,
            projectName,
          })
        }

        setOvertimeRecords([ ...workingRecords ]);
        setTotalCount(totalCount || 0);
      } catch (err) { console.log(err) }

      setShowLoadingPopup(false);
    }
    if (searchedWorkingStartDate !== null && searchedWorkingEndDate  != null) {
      console.log("overtimesList.js useEffect searchedWorkingStartDate" + searchedWorkingStartDate);
      fetchWorkingHoursByIan();
    }
  }, [searchedWorkingStartDate, searchedWorkingEndDate]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelOvertimesSearch
          config = { config }
          userFilter = { userFilter }
          memberOptions = { memberOptions }
          projectIDOptions = { projectIDOptions }
          workingHoursTypeOptions = { workingHoursTypeOptions }
          workingHoursTagDictionary = { workingHoursTagDictionary }
          clearHandler = { clearHandler }
          searchHandler = { searchHandler }
          exportHandler = { exportHandler }
          updateConfigHandler = { updateConfigHandler }
          typeChangeHandler = { typeChangeHandler }
          userFilterChangeHandler = { userFilterChangeHandler }
        />
      </DivPanelContainer>
      <TableOvertimeList
        overtimeRecords = { overtimeRecords }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
    </MainContent>
  );
};

export default OvertimesList;
