import React from 'react';
import styled from 'styled-components';

import TableWrapper from '@/components/UI/TableWrapper';
import Checkbox from '@/components/UI/Checkbox';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import VendorTypeList from '@/components/VendorTypeList';
import SelectMultiple from '@/components/UI/Select/SelectMultiple';
import VendorSpecialProperties from '@/components/UI/VendorSpecialProperties';
import AddressInfoSelector from '@/components/UI/AddressInfoSelector';
import { InputBasic, TextareaBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
  DivFullWidthWrapper,
  DivLongMultiSelector,
} from '@/component-style/RWDWrapper';

import {
  vendorPayments,
  vendorInvoiceTypes,
  vendorInvoicePeriod,
  vendorQualifications,
} from '@/constant/options';

import MyInputNumber from '@/components/UI/MyInputNumber';
// import TextArea from 'antd/lib/input/TextArea';
import MyTextArea from '@/components/UI/MyTextArea';

const DivCheckboxGroup = styled(DivFlexRow)`
  padding: 0;
`;

const PanelVendorDetail = props => {
  const { vendor } = props;

  // const myTextArea = {
  //   marginBottom: '10px', 
  //   color: '#8b8b8b', 
  //   minHeight: '38px',
  //   width: '300px', 
  //   borderRadius: '8px', 
  //   border: 'solid 2px #d9d9d9',
  //   textarea : {
  //     '&:focus' : { 
  //       outline: 'none !important',
  //       border: 'solid 2px #d9d9d9',
  //     }
  //   }
  // };

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            廠商名稱
            <span>*</span>
          </PSubtitle>
          <div>
            <MyTextArea
              // style = { myTextArea }
              disabled = { props.disabled || vendor.financeApproved}
              value = { vendor.vendorName }
              onChange = {({ target }) => {
                props.updateVendorHandler('vendorName', target.value);
              }}
            />
            {/* <InputBasic
              style = {{ marginBottom: '10px' }}
              disabled = { props.disabled }
              value = { vendor.vendorName }
              onChange = {({ target }) => {
                props.updateVendorHandler('vendorName', target.value);
              }}
            /> */}
            {
              // props.showAccountingItems &&
              <DivCheckboxGroup>
                <Checkbox
                  title = "禁止使用"
                  disabled = { props.disabled }
                  checked = { !vendor.available }
                  changeHandler = { value => {
                    props.updateVendorHandler('available', !value);
                  }}
                />
                <Checkbox
                  title = "會計審核"
                  disabled = { props.disabled }
                  checked = { vendor.financeApproved }
                  changeHandler = { value => {
                    props.updateVendorHandler('financeApproved', value);
                  }}
                />
              </DivCheckboxGroup>
            }
          </div>
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>廠商密碼</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { vendor.password }
            onChange = {({ target }) => {
              props.updateVendorHandler('password', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>統一編號</PSubtitle>
          <InputBasic
            disabled = { props.disabled || vendor.financeApproved }
            value = { vendor.taxId }
            onChange = {({ target }) => {
              props.updateVendorHandler('taxId', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>負責人</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { vendor.principal }
            onChange = {({ target }) => {
              props.updateVendorHandler('principal', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>特殊屬性</PSubtitle>
          <VendorSpecialProperties
            disabled = { props.disabled }
            properties = { vendor.specialProperties }
            specialFeatures = { props.specialFeatures }
            updatePropertiesHandler = { value => {
              props.updateVendorHandler('specialProperties', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>聯絡地址</PSubtitle>
          <AddressInfoSelector
            disabled = { props.disabled }
            address = { vendor.contactAddress }
            updateAddressHandler = { value => {
              props.updateVendorHandler('contactAddress', value);
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>發票地址</PSubtitle>
          <AddressInfoSelector
            showCopyButton = { true }
            disabled = { props.disabled }
            address = { vendor.invoiceAddress }
            updateAddressHandler = { value => {
              props.updateVendorHandler('invoiceAddress', value);
            }}
            copyAddressHandler = {  () => {
              props.updateVendorHandler(
                'invoiceAddress',
                { ...vendor.contactAddress }
              );
            }}
          />
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>網址</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { vendor.website }
            onChange = {({ target }) => {
              props.updateVendorHandler('website', target.value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>電話</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { vendor.phone }
            onChange = {({ target }) => {
              props.updateVendorHandler('phone', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>服務項目</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { vendor.services }
              onChange = {({ target }) => {
                props.updateVendorHandler('services', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>廠商分類</PSubtitle>
          <VendorTypeList
            disabled = { props.disabled }
            tags = { vendor.tags }
            vendorTypeOptions = { props.vendorTypeOptions }
            vendorTagDictionary = { props.vendorTagDictionary }
            updateVendorTypesHandler = { value => {
              props.updateVendorHandler('tags', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>廠商資格</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { vendor.qualification }
            options = { vendorQualifications }
            changeHandler = { value => {
              props.updateVendorHandler('qualification', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>發票收據種類</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { vendor.invoiceType }
            options = { vendorInvoiceTypes }
            changeHandler = { value => {
              props.updateVendorHandler('invoiceType', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>付款方式</PSubtitle>
          <DivLongMultiSelector>
            <SelectMultiple
              disabled = { props.disabled || vendor.financeApproved }
              value = { vendor.paymentMethods }
              options = { vendorPayments }
              changeHandler = { props.paymentChangedHandler }
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      {
        vendor.paymentMethods.indexOf('credit') !== -1 &&
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>公司信用卡持卡人</PSubtitle>
            <SelectNormal
              disabled = { props.disabled || vendor.financeApproved }
              value = { vendor.credictCardOwner }
              options = {[
                { value: '', desc: '請選擇持卡人' },
                ...props.memberOptions,
              ]}
              changeHandler = { value => {
                props.updateVendorHandler('credictCardOwner', value);
              }}
            />
          </DivItemWrapper>
        </DivFlexRow>
      }
      {
        (vendor.paymentMethods.indexOf('transfer') !== -1 || vendor.paymentMethods.indexOf('銀行轉帳') !== -1) &&
        <React.Fragment>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>轉帳銀行名</PSubtitle>
              <InputBasic
                disabled = { props.disabled }
                value = { vendor.transferInfo.bankName }
                onChange = {({ target }) => {
                  props.updateVendorHandler('transferInfo', {
                    ...vendor.transferInfo,
                    bankName: target.value,
                  });
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>轉帳分行名</PSubtitle>
              <InputBasic
                disabled = { props.disabled || vendor.financeApproved }
                value = { vendor.transferInfo.branchName }
                onChange = {({ target }) => {
                  props.updateVendorHandler('transferInfo', {
                    ...vendor.transferInfo,
                    branchName: target.value,
                  });
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>轉帳戶名</PSubtitle>
              <InputBasic
                disabled = { props.disabled || vendor.financeApproved }
                value = { vendor.transferInfo.accountName }
                onChange = {({ target }) => {
                  props.updateVendorHandler('transferInfo', {
                    ...vendor.transferInfo,
                    accountName: target.value,
                  });
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>轉帳帳號</PSubtitle>
              <InputBasic
                disabled = { props.disabled || vendor.financeApproved }
                value = { vendor.transferInfo.accountId }
                onChange = {({ target }) => {
                  props.updateVendorHandler('transferInfo', {
                    ...vendor.transferInfo,
                    accountId: target.value,
                  });
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>手續費支付方</PSubtitle>
              <SelectNormal
                disabled = { props.disabled || vendor.financeApproved }
                value = { vendor.transferInfo.payHandlingFee }
                options = {[
                  { value: true, desc: '我方' },
                  { value: false, desc: '廠商' },
                ]}
                changeHandler = { value => {
                  props.updateVendorHandler('transferInfo', {
                    ...vendor.transferInfo,
                    payHandlingFee: value,
                  });
                }}
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>手續費費用</PSubtitle>
              <MyInputNumber
                disabled = { props.disabled || vendor.financeApproved }
                value = { vendor.transferInfo.handlingFee }
                onChange = { value => {
                  props.updateVendorHandler('transferInfo', {
                    ...vendor.transferInfo,
                    handlingFee: value,
                  });
                }}
              />
              {/* <InputBasic
                type = "number"
                disabled = { props.disabled }
                value = { vendor.transferInfo.handlingFee }
                onChange = {({ target }) => {
                  props.updateVendorHandler('transferInfo', {
                    ...vendor.transferInfo,
                    handlingFee: target.value,
                  });
                }}
              /> */}
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>結帳天數</PSubtitle>
              <SelectNormal
                disabled = { props.disabled || vendor.financeApproved }
                value = { vendor.transferInfo.settleDays }
                options = { vendorInvoicePeriod }
                changeHandler = { value => {
                  props.updateVendorHandler('transferInfo', {
                    ...vendor.transferInfo,
                    settleDays: value,
                  });
                }}
              />
            </DivItemWrapper>
          </DivFlexRow>
        </React.Fragment>
      }
      <DivFlexRow>
        <DivFullWidthWrapper>
          <PSubtitle>備註說明</PSubtitle>
          <DivLongMultiSelector>
            <TextareaBasic
              disabled = { props.disabled }
              value = { vendor.note }
              onChange = {({ target }) => {
                props.updateVendorHandler('note', target.value);
              }}
            />
          </DivLongMultiSelector>
        </DivFullWidthWrapper>
      </DivFlexRow>
      { props.children }
    </TableWrapper>
  );
};

export default PanelVendorDetail;
