import React, { useState, useEffect, useCallback  } from 'react';

import TableWrapper from '@/components/UI/TableWrapper';
import DateSelector from '@/components/UI/DateSelector';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import PopupDraggable from '@/components/UI/Popup/PopupDraggable';
import PopupDraggablePdf from '@/components/UI/Popup/PopupDraggablePdf';
import SelectSearchable from '@/components/UI/Select/SelectSearchable';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import {
  addTime,
  // diffTimeWithFloat,
  diffTimeSubtractLunchBreak,
  toUTCTime,
  formatTime,
} from '@/utils/timeUtils';

import {
  getDownloadFile,
  deleteUploadFile,
  postToGetUploadFiles,
  postNewUploadFile,
} from '@/api/storageService';

import {
  getLeaveSetting,
} from '@/api/financeService';

import moment from 'moment';

import PopupAttachmentList from '@/components/UI/Popup/PopupAttachmentList';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';

const ButtonFilesUpload = styled(ButtonBasic)`
  width: 120px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 10px;

  svg { margin-left: 5px }
`;

const ButtonFilesEmpty = styled(ButtonCancel)`
  width: 120px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
`;

// import { overtimeDateTypeOptions } from '@/constant/options';

const PanelLeaveDetail = props => {
  const { leaveApplication, overtime, workingHoursTagDictionary } = props;
  console.log(`PanelOvertimeDetail index.js leaveApplication=${JSON.stringify(props.leaveApplication)}`);
  // console.log("PanelOvertimeDetail index.js props.approvedUser:" + JSON.stringify(props.approvedUser));

  const tagOptions = typeID => 
    workingHoursTagDictionary[typeID] === undefined
      ? []
      : workingHoursTagDictionary[typeID];

  const workingHours = diffTimeSubtractLunchBreak(overtime.startTimeLocal, overtime.endTimeLocal, 'hours');

  const disabledStartDate = current => current > addTime(overtime.workingDate, 1, 'days')
    || current <= toUTCTime(overtime.workingDate);

  const disabledEndDate = current => current > addTime(overtime.workingDate, 1, 'days')
    || current <= toUTCTime(overtime.workingDate);

  const [showPopupAttachment, setShowPopupAttachment] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileUrl, setFileUrl] = useState('');
  const [file, setFile] = useState(null);
  const [showDraggablePDFPopup, setShowDraggablePDFPopup] = useState(false);
  const [showDraggableImagePopup, setShowDraggableImagePopup] = useState(false);
  

  const updateStartTimeHandler = value => {
    props.updateLeaveApplicationHandler(
      'leaveFrom',
      formatTime(value, 'YYYY/MM/DD HH:mm')
    );
  };

  const updateEndTimeHandler = value => {
    props.updateLeaveApplicationHandler(
      'leaveTo',
      formatTime(value, 'YYYY/MM/DD HH:mm')
    )
  };

  const fileManageHandler = () => {
    setShowPopupAttachment(true);
  };

  const displayFileHandler = async fileInfo => {
    const { name, fileID, downloadURL } = fileInfo;

    if (name.match(/\.pdf$/)) {
      // openUploadPopup(false);
      // setShowLoadingPopup(true);
      const { data } = await getDownloadFile(fileID);
      
      setFile(data);
      // setShowLoadingPopup(false);
      setShowDraggablePDFPopup(true);
    } else {
      setFileUrl(downloadURL);
      // openUploadPopup(false);
      setShowDraggableImagePopup(true);
    }
  };

  const fileUploadButton = props.isFileUpload
    ? <ButtonFilesUpload
        onClick = { fileManageHandler }
      >附件管理
        <FontAwesomeIcon icon = { faFolderOpen }/>
      </ButtonFilesUpload>
    : <ButtonFilesEmpty
        onClick = { fileManageHandler }
      >附件管理</ButtonFilesEmpty>

  // const fetchLeaveSetting = async () => {
  //   try {
  //     const { data } = await getLeaveSetting();
  //     console.log("PanelLeaveDetail index.js fetchLeaveSetting() data:" + JSON.stringify(data));
  //   } catch (err) { console.log(err) }
  // };

  // const initConfigData = useCallback(
  //   fetchLeaveSetting, [])

  // useEffect(() => {
  //   initConfigData();
  // }, [initConfigData])

  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            開始日期及時間
            <span>*</span>
          </PSubtitle>
          <DateSelector
            showTime = { true }
            disabled = { props.disabled === true ? true : false }
            // disabledDate = { disabledStartDate }
            value = { leaveApplication.leaveFrom == null ? null : moment.parseZone(leaveApplication.leaveFrom).format('YYYY/MM/DD HH:mm') }
            okHandler = { updateStartTimeHandler }
            changeHandler = { updateStartTimeHandler }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>
            結束日期及時間
            <span>*</span>
          </PSubtitle>
          <DateSelector
            showTime = { true }
            // disabled = { props.disabled || overtime.workingDate === null }
            // disabledDate = { disabledStartDate }
            disabled = { props.disabled === true ? true : false }
            value = { leaveApplication.leaveTo == null ? null : moment.parseZone(leaveApplication.leaveTo).format('YYYY/MM/DD HH:mm') }
            okHandler = { updateEndTimeHandler }
            changeHandler = { updateEndTimeHandler }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            假別
            <span>*</span>
          </PSubtitle>
          <SelectSearchable
            disabled = { props.disabled === true ? true : false }
            value = { leaveApplication.leaveId }
            options = {[
              { value: '', desc: '請選擇假別' },
              ...props.leaveSettinsOptions,
            ]}
            changeHandler = { value => {
              props.updateLeaveApplicationHandler('leaveId', value);
            }}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>備註</PSubtitle>
          <InputBasic
            // disabled = { props.disabled || overtime.workingDate === null }
            disabled = { props.disabled === true ? true : false }
            value = { leaveApplication.description }
            onChange = { ({ target }) => {
              props.updateLeaveApplicationHandler('description', target.value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>
            休假時數
          </PSubtitle>
          <InputBasic
            disabled = { true }
            value = { leaveApplication.durationInSeconds === undefined ? "" : Math.round(leaveApplication.durationInSeconds / 3600 * 100) / 100 }
          />
        </DivItemWrapper>
      </DivFlexRow>
      {leaveApplication.id !== undefined &&
       <DivFlexRow>
        <DivItemWrapper>
          { fileUploadButton }
        </DivItemWrapper>
        </DivFlexRow>
      }
      {
        showPopupAttachment &&
        <PopupAttachmentList
          title = "附件管理"
          displayable = { true }
          fileList = { props.fileList }
          uploadFiles = { props.uploadFiles }
          displayFileHandler = { displayFileHandler }
          cancelHandler = { () => { setShowPopupAttachment(false) }}
          // updateUploadFiles = { files => { setUploadFiles([ ...files ]) }}
          updateUploadFiles = { files => { props.setUpoloadFilesHandler(files) }}
          fileChangable = { true }
          fileUploadHandler = { props.fileUploadHandler }
          deleteFileHandler = { props.deleteFileHandler }
        />
      }
      {
        showDraggableImagePopup &&
          <PopupDraggable
            url = { fileUrl }
            cancelHandler = { () => {
              setShowDraggableImagePopup(false);
              // openUploadPopup(true);
            }}
          />
      }
      {
        showDraggablePDFPopup &&
          <PopupDraggablePdf
            file = { file }
            cancelHandler = { () => {
              setShowDraggablePDFPopup(false);
              // openUploadPopup(true);
            }}
          />
      }
      {
        props.showApprovement &&
        <React.Fragment>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>核准人</PSubtitle>
              <InputBasic
                disabled = { true }
                value = { props.approvedUser.name == null ? "" : props.approvedUser.name }
                // value = { props.approvedUser == null ? "" : props.approvedUser.approvementUserID }
                // value = { props.approvedUser == null ? "" : props.approvedUser.account }
              />
            </DivItemWrapper>
            <DivItemWrapper>
              <PSubtitle>核准日期</PSubtitle>
              <InputBasic
                disabled = { true }
                value = { overtime.approvementDate }
              />
            </DivItemWrapper>
          </DivFlexRow>
          <DivFlexRow>
            <DivItemWrapper>
              <PSubtitle>申請日期</PSubtitle>
              <InputBasic
                disabled = { true }
                value = { overtime.submitDate }
              />
            </DivItemWrapper>
          </DivFlexRow>
        </React.Fragment>
      }
      { props.children }
    </TableWrapper>
  );
};

export default PanelLeaveDetail;
